<template>
    <b-modal id="addActionPlanModal" hide-footer :title="payload.text" style="height: 100%;">
        <template #modal-header style="background-color: #EBEDF0;">
            <div class="w-100 d-flex" >
                <h6 class="m-1" style="font-weight: bold;"><span class="badge-item badge-item-key-action"></span> Add Key Action</h6> <br>
                 
                <span v-if="$hasPerm('Power User')" class="form-check form-switch me-3 ms-auto" style="font-size: 10px;">
                </span>
                <font-awesome-icon @click="$bvModal.hide('addActionPlanModal')" style="cursor: pointer; padding-top: 2px;" icon="fa-times"></font-awesome-icon>

            </div>
        </template>
        
        <!-- <div class="modal-title">
           Details   
        </div> -->
        <div class="mt-0" v-if="!showProjectsTree">
            
            <div v-show="change_objective_mode == false">
                <b-form-group class="label" id="input-group-1" label="Linked Level/Objective/Target" label-for="input-1" size="sm">
                    <div @click="change_objective_mode = !change_objective_mode" class="cursor-pointer">
                        <div class="d-flex">
                            <!-- <span v-show="selectedName != ''">Selected {{ selectedType }} : </span>  -->
                            <span v-show="selectedType == 'objective'" class="badge-item badge-item-objective mr-1"></span>
                            <span v-show="selectedType == 'target'" class="badge-item badge-item-target mr-1"></span>
                            <span style="margin-left: 3px; margin-top: 1px"> {{ selectedName }}</span> <i class="me-1 fas fa-pen" style="margin-left: 5px; margin-top: 3px"></i>   
                        </div >
                    </div>
                </b-form-group>
                <b-form-group class="label" id="input-group-1" label="Linked Projects:" label-for="input-1" size="sm">
                    <div>
                        <div class="d-flex" style="justify-content: space-between;">
                            <div class="d-flex">  
                                <!-- <span v-if="selectedType == 'objective'" class="badge-item badge-item-objective mr-1"></span>
                                <span v-if="selectedType == 'target'" class="badge-item badge-item-target mr-1"></span>
                                <span v-if="selectedType == 'level'" class="badge-item badge-item-level mr-1"></span> -->
                                
                                <span v-if="linked_projects.length != 0"><span v-for="(item, idx) in linked_projects" :key="idx">{{ item.name }} <span v-if="idx == 0 && linked_projects.length != 1"> , </span> </span><i class="me-1 fas fa-pen" @click="showProjectsTree = !showProjectsTree" style="margin-left: 5px; margin-top: 3px"></i></span>
                                <span v-if="linked_projects.length == 0" style="margin-left: 2px">No Projects Linked <i class="me-1 fas fa-pen" @click="showProjectsTree = !showProjectsTree" style="margin-left: 5px; margin-top: 3px"></i></span> 
                            </div>
                        </div>    
                    </div>   
                </b-form-group>
                <div v-show="!change_objective_mode">
                    <b-form @submit="onSubmit" v-if="show">
                    <b-form-group class="label" id="input-group-1" label="Key Action Name:" label-for="input-1" size="sm">
                        <b-form-input
                        id="input-1"
                        size="sm"
                        v-model="form.name"
                        placeholder="Enter Name"
                        required
                        ></b-form-input>
                    </b-form-group>

                    <div class="container">
                        <div class="row">
                            <div class="col-sm p-0">
                                <b-form-group id="input-group-2" label="Start Date:" label-for="input-2" size="sm" >
                                    <b-form-input
                                    id="input-2"
                                    size="sm"
                                    v-model="form.start_date"
                                    type="date"
                                    placeholder="Select Start Date"
                                    required
                                    ></b-form-input>
                                </b-form-group>
                            </div>
                            <div class="col-sm p-0" style="margin-left: 4px;">
                                <b-form-group id="input-group-3" label="End Date:" label-for="input-3" size="sm" >
                                    <b-form-input
                                    id="input-3"
                                    size="sm"
                                    type="date"
                                    v-model="form.end_date"
                                    placeholder="Select End Date"
                                    required
                                    ></b-form-input>
                                </b-form-group>
                            </div>
                        </div>
                    </div>

                    <b-form-group id="input-group-4" label="Owner:" label-for="input-4" size="sm">
                        <vue-typeahead-bootstrap
                        ref="userSearch"
                        :data="users"
                        size="sm"
                        v-model="owner_query"
                        placeholder="Search name/email"
                        @hit="selectedOwner"
                        showOnFocus
                        prepend="Users:"
                    ></vue-typeahead-bootstrap>
                    </b-form-group>

                    <b-form-group id="input-group-5" label="Responsible User:" label-for="input-5" size="sm">
                        <vue-typeahead-bootstrap
                        ref="userSearch"
                        :data="users"
                        size="sm"
                        v-model="user_query"
                        placeholder="Search name/email"
                        @hit="selectedResponsibleUser"
                        showOnFocus
                        prepend="Users:"
                    ></vue-typeahead-bootstrap>
                    </b-form-group>

                    <b-form-group class="label" id="input-group-6" label="Notes:" label-for="input-6" size="sm">
                        <b-form-textarea
                        id="input-6"
                        size="sm"
                        v-model="form.notes"
                        placeholder="Notes"
                        ></b-form-textarea>
                    </b-form-group>
                    <b-button type="submit" class='ms-auto mt-2' style="float: right" variant='primary' size="sm">Save Key Action</b-button>
                    </b-form>
                </div>
            </div>

            <div v-if="change_objective_mode">
                <div class="pb-1">  
                    <b-button @click="change_objective_mode = false" variant="lightr" size="sm">
                        <font-awesome-icon icon="fa-arrow-left"/>
                    </b-button>
                    <span class="ms-1">Link Key Action to either a Level, Target or Objective</span>
                </div>
                <b-overlay :show="is_loading">
                <!-- <div class="d-flex">
                    <b-form-input v-model.lazy="filter_input" placeholder="Search Targets"></b-form-input>
                    <b-button class="ms-1"><font-awesome-icon icon="fa-times"></font-awesome-icon></b-button>
                </div> -->
                <div class="d-flex">
                    <div class="modal-title m-1">
                        <span v-show="selectedName != ''">Selected {{ selectedType }} : </span> 
                        <span v-show="selectedType == 'objective'" class="badge-item badge-item-objective mr-1"></span>
                        <span v-show="selectedType == 'target'" class="badge-item badge-item-target mr-1"></span>
                        <!-- <span class="badge-item badge-item-objective mr-1"></span> -->
                        <span style="font-weight: bold" class="text-primary">{{selectedName}}</span>
                    </div>
                </div>
                <!-- <vue-typeahead-bootstrap
                        ref="targetOrObjectiveSearch"
                        :data="searchList"
                        v-model="query"
                        placeholder="Search Target or Objective"
                        showOnFocus
                        @hit="selectedItemEvent"
                        @input="search"
                        prepend="Targets & Objectives:"
                    ></vue-typeahead-bootstrap> -->
                    
                <webix-ui v-if="change_objective_mode" style="height: 100%;" :config="ui" v-model="level_data" ></webix-ui>
                </b-overlay>
            </div>
        </div>
        <projectsTree v-if="showProjectsTree" :payload="linked_projects" @selectedNewIDs="updateProjectsList" />
    </b-modal>
</template>

<script>
import { levelComputed, levelMethods, activityComputed, activityMethods, profileComputed, profileMethods, ganttComputed, ganttMethods, performanceComputed } from '@/state/helpers'
import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap'
import { cloneDeep } from 'lodash'
import _ from 'lodash'
import { format, parseISO } from 'date-fns';
import Swal from 'sweetalert2';
import dateMixin from '@/mixins/dateMixin.js'
import projectsTree from '../projectsTree.vue';
export default {
components:{
    VueTypeaheadBootstrap,
    projectsTree
},
mixins:[ dateMixin ],
props:{
    payload:{
        required: true,
    },
},
data:() => ({
    searchList: [],
    objectivesList: [],
    openObjectivesList: [],
    is_loading: false,
    is_loading_all_linkable_plans: false,
    linkable_plans_data: [],
    show_all_linkable_plans: false,
    change_objective_mode: false,
    users: [],
    filter_input: '',
    combined_targets_array: [],
    selectedPlainId: 0,
    combined_objectives_array: [],
    linked_objective_name: '',
    linked_key_result_name: '',
    new_objective: false,
    query: '',
    selectedId: '',
    selectedName: '',
    main_linked_projects: [],
    selectedType: '',
    owner_query: '',
    user_query: '',
    linked_projects: [],
    showProjectsTree: false,
    showProjectTree: false,
    form: {
            id: -1,
            parent_id: 0,
            level_id: null,
            objective_id: null,
            target_id: null,
            key_result_id: null,
            name: '',
            start_date: '',
            end_date: '',
            type:  "plan",
            description: 'Empty',
            body: 'Empty',
            notes: '',
            status: '0',
            priority: 'low',
            owner_id: '',
            linked_project_ids: [],
            responsible_user_id: '',
    },
    show: true,
    parent_item_id: -1,
    parent_item_type: '',
    parent_name: '',
}),
watch:{
    start_date: {
        handler() {
          console.log("selecting date")
        }
    },
    selectedId:{
        //immediate: true,
        handler(newValue, oldValue){
            console.log(this.selectedTargetId)
            if(this.selectedTargetId != null){
                let mainTree = window.webix.$$('levelsTargetsObjectivesTree');
                if(mainTree){
                    if(mainTree.exists(newValue)){
                        mainTree.select(newValue);
                        mainTree.addCss(newValue, 'selected_style');
                    }
                }
                if(oldValue && mainTree.exists(oldValue)){
                    mainTree.removeCss(oldValue, 'selected_style')
                }
            }
        }
    },
    change_objective_mode: {
        handler() {
            if(this.change_objective_mode == true) {
                console.log("1")
                this.is_loading = true
                console.log('building the tree')
                this.setTreeData();
                this.initModal();
                
            } else {
                console.log("2")
                window.webix.$$('levelsTargetsObjectivesTree').destructor();
            }
        }
    },
    searchItems:{
        //immediate: true,
        handler(){
            if(this.searchItems != undefined) {
                let searchableItems = []
                this.searchItems.forEach(item => {
                    searchableItems.push(item.name)
                })
                this.searchList = searchableItems
            }
        }
    },
},
computed:{
    ...levelComputed,
    ...activityComputed,
    ...profileComputed,
    ...ganttComputed,
    ...performanceComputed,
    ui(){
            let el = this;
            return{
                id: 'levelsTargetsObjectivesTree',
                view: 'tree',
                css: 'levelTree',
                scroll: true,
                drag: false,
                select: true,
                // //data: this.level_data,
                template: (obj, common) => {
                    let id = obj.id
                    if(isNaN(id)) {
                        console.log(obj)
                        if(id.includes('target')) {
                            return common.icon(obj, common) + `<span class="badge-item badge-item-target mr-1"></span><span class="normal-text tree_styles"> ${obj.name} - <span style="font-weight: bold">${obj.owner_name}</span></span>`;
                        } else {
                            return common.icon(obj, common) + `<span class="badge-item badge-item-objective mr-1"></span><span class="normal-text tree_styles"> ${obj.text} - <span style="font-weight: bold">${obj.owner_name}</span></span>`;
                        }                       
                    } else {
                        return common.icon(obj, common) + `<span class="normal-text tree_styles">${obj.name}</span>`;
                    }
                },
                //maxHeight: 500,
                filterMode:{
                    showSubItems:false,
                },
                ready(){
                    let targets_array = cloneDeep(el.targets);
                        let combined_targets = []
                        targets_array.forEach(( target )=>{ 
                            let branch_id = target.level_id
                            if(this.exists(branch_id)){
                                let mutated_target = cloneDeep(target);
                                mutated_target.id = `${branch_id}|target-${mutated_target.id}`
                                console.log(mutated_target.id);
                                combined_targets.push(target)
                                this.add(mutated_target, 0,branch_id);
                            }
                        })
                        if(el.combined_targets_array.length == 0) {
                            let value = el.combined_targets_array
                            el.combined_targets_array = value.concat(combined_targets);
                            console.log(el.combined_targets_array)
                        }

                        let objectives_array = cloneDeep(el.gantt_tasks);
                        let combined_objectives = []
                        objectives_array.forEach((objective )=>{ 
                            if(objective.id.includes('objective')) {
                                let branch_id = el.selected_level.id
                                if(this.exists(branch_id)){
                                    let mutated_objective = cloneDeep(objective);
                                    if(mutated_objective.$parent != 0) {
                                        let objective = el.objective_details_data.objectives.find( objective => { return objective.id == mutated_objective.plain_id; })
                                        console.log(JSON.parse(JSON.stringify(objective)))
                                        if(objective) {
                                            mutated_objective.owner_name = objective.owner_name
                                        }
                                    } else {
                                        mutated_objective.user_name = 'No Owner'
                                    }
                                    mutated_objective.id = `${branch_id}|objective-${mutated_objective.plain_id}`
                                    console.log(mutated_objective.id);
                                    combined_objectives.push(objective)
                                    this.add(mutated_objective, 0,branch_id);
                                }
                            }
                        })
                        if(el.combined_objectives_array.length == 0) {
                            let value = el.combined_objectives_array
                            el.combined_objectives_array = value.concat(combined_objectives);
                            console.log(el.combined_objectives_array)
                        }
                },
                on:{
                    onItemClick(id){
                        console.log(id)
                        //let el = this.$scope.$parent;
                        if(id.includes('target')) {
                            console.log(id);
                            let name = ''
                            let mainId = id.split('|')[1];
                            let level_id = id.split('|')[0];
                            let target_id = mainId.split('-')[1]
                            console.log(target_id);
                            //set to combined array
                            el.targets.forEach(item => {
                                if(item.id == target_id) {
                                    name = item.name
                                }
                            })
                            console.log(name)
                            el.selectedType = 'target'
                            el.selectedName = name
                            el.selectedPlainId = target_id
                            el.selectedId = `${level_id}|target-${target_id}`
                            el.change_objective_mode = false
                            //el.clickItemEvent(name)
                        } else if(id.includes('objective')) {
                            console.log(id);
                            let name = ''
                            let mainId = id.split('|')[1];
                            let level_id = id.split('|')[0];
                            let objective_id = mainId.split('-')[1]
                            //set to combined array
                            el.gantt_tasks.forEach(item => {
                                if(item.id.includes('objective')) {
                                    if(item.plain_id == objective_id) {
                                       name = item.text
                                    }
                                }
                                
                            })
                            console.log(name)
                            el.selectedType = 'objective'
                            el.selectedName = name
                            el.selectedPlainId = objective_id
                            el.selectedId = `${level_id}|objective-${objective_id}`
                            el.change_objective_mode = false
                        } else {
                            el.selectedType = 'level'
                            console.log(id)
                            let index = _.findIndex(el.levels, {id: Number(id)});
                            console.log(index)
                            let name = ''
                            if( index != -1){
                                name = el.levels[index].name;
                            }
                            el.selectedName = name
                            el.selectedId = id
                            el.change_objective_mode = false
                        }
                    },
                    onBeforeOpen(id) {
                        let num = Number(id)
                        if(num != isNaN) {
                           console.log(id)
                           el.level_to_load = Number(id)
                        }
                    }
                },
            }
        },
},
methods:{
    ...activityMethods,
    ...profileMethods,
    ...levelMethods,
    ...ganttMethods,
    // search: debounce(function() {
    //     console.log(this.query)
    //     this.searchItem({type: 'target', search_name: this.query})
    //     .then(()=>{   
    //     })
    //     this.searchItem({type: 'objective', search_name: this.query})
    //     .then(()=>{   
    //     })
    //   }, 500),
    updateProjectsList(data) {
       console.log(JSON.parse(JSON.stringify(data)))
       let ids = []
       if(data.length != 0) {
        data.forEach(item => {
            ids.push(item.id)
        })
       }
       this.form.linked_project_ids = ids
       this.showProjectsTree = false
       this.linked_projects = data
    },
    initModal(){
        this.is_loading = true
        setTimeout(() => {
            
            let matches = []
                //if(this.selectedId.includes('target') || this.selectedId.includes('objective')) {
            matches.push(this.selectedId);
            if(matches.length > 0){
                let tree = window.webix.$$('levelsTargetsObjectivesTree');
                    matches.forEach( match => {
                        if(tree.exists(match)) {
                            let promises = [ this.loadDataFromParents(matches) ];
                            Promise.all(promises)
                            .then(()=>{
                                setTimeout(() => {
                                    tree.addCss(matches[0], 'selected_style');
                                    this.openTreeNode(matches, true);
                                    this.is_loading = false
                                    //this.resetTypeahead();
                                }, 1500) 
                                
                            })
                        }
                    })
                
            }
        }, 500)
    },
    cloneDataFromGanttToCurrentTable(){
        var copy = window.webix.copy(window.webix.$$("myGanttTree").serialize());
        window.webix.$$("clonedTree").parse(copy);
    },
    getObjectiveName() {
        console.log(this.parent_item_id)
        if(this.parent_item_id !== 0) {
            let parent_name = this.gantt_tasks.find( task => {
                    let task_type = 'objective'
                    if(task.item_type == 'key_result'){
                        task_type = 'kr'
                    }
                    if(task.plain_id == this.parent_item_id && this.parent_item_type == task_type) {
                        return task.plain_id == this.parent_item_id
                    }    
            });
            if(parent_name.id.includes('kr')){
                this.new_objective = true
                this.linked_key_result_name = parent_name.text
            } else {
                this.new_objective = true
                this.linked_objective_name = parent_name.text
            }
        } else {
            this.linked_objective_name = "Link to Level, Objective or Target"
        }
    },
    selectedOwner(item) {
        let email = item.split('|')[1];
        let email_string = email.trim();
        let user = this.all_users.find( item => {
            return item.email == email_string
        });
        this.form.owner_id = user.id
    },
    selectedResponsibleUser(item) {
        let email = item.split('|')[1];
        let email_string = email.trim();
        let user = this.all_users.find( item => {
            return item.email == email_string
        });
        this.form.responsible_user_id = user.id
    },
    onSubmit(event) {
        event.preventDefault()
        this.form.level_id = this.selected_level.id
        //this.form.type = this.selectedType
        //this.form.objective_id = this.parent_item_id
        this.form.start_date = format(parseISO(this.form.start_date), 'yyyy-MM-dd')
        this.form.end_date = format(parseISO(this.form.end_date), 'yyyy-MM-dd')
        let endCheck = this.endDateCheck(this.form.end_date, this.form.start_date)
        let startCheck = this.startDateCheck(this.form.start_date, this.form.end_date);
        // if(this.parent_item_type == 'kr') {
        //     this.form.objective_id = null
        //     this.form.key_result_id = this.parent_item_id
        // }
        if(!endCheck || !startCheck) {
            Swal.fire({
                title: "Invalid Dates",
                text: "Please order dates correctly",
                icon: "warning",
            })
        } else {
            console.log("saving")
            let activity_params = null
            if(this.selectedType == 'target') {
                this.form.target_id = this.selectedPlainId
                activity_params = {
                    key_actions: [this.form],
                }
            } else if (this.selectedType == 'objective') {
                this.form.objective_id = this.selectedPlainId
                activity_params = {
                    key_actions: [this.form],
                }
            } else {
                activity_params = {
                    key_actions: [this.form],
                }
            }
            
            console.log(JSON.parse(JSON.stringify(activity_params)))
            this.saveActivityInbox(activity_params) 
            .then(()=>{
                this.$emit('saved')
                this.savedKeyAction(true);
                this.$bvModal.hide('addActionPlanModal');
            })
        }
    },
    populateList(){
        let users = [];
        this.all_users.forEach( user =>{
            users.push(`${user.name} | ${user.email}`)
        });
        this.users = users;
    },
    setTreeData(){
        let temp_level_data = _.cloneDeep(this.levels);
        //temp_level_data = _.orderBy(temp_level_data, ['item_order']);
        this.level_data = this.processLevels(temp_level_data);
    },
    openTreeNode(id_array, searched){
        let tree = window.webix.$$('levelsTargetsObjectivesTree');
        if(tree){
            let item = tree.getItem(id_array[0]);
            tree.closeAll()
            while(item.$parent != 0){
                tree.open(item.$parent);
                item = tree.getItem(item.$parent);  
            }
            this.is_loading = false
            if(searched) {
                tree.showItem(id_array[0]);
            }
        }
    },
    loadDataFromParents(id_array) {
        let tree = window.webix.$$('levelsTargetsObjectivesTree');
        let data = []
        if(tree){
            let item = tree.getItem(id_array[0]);
            while(item.$parent != 0){
                data.push(item.$parent);
                tree.open(item.$parent);
                item = tree.getItem(item.$parent);  
            }
        }
        console.log(data)
        // data.forEach(itemID => {
        //     this.loadPerformanceTargets(itemID).then(()=>{
        //         this.buildTree();
        //         this.populateTargetList()
        //         this.show_add_target = false;
        //     })
        // })
    },
    processLevels(levels){
            this.show = false;
            var flat_array = levels != null ? _.cloneDeep(levels) : [];
            const map = {};
            const nestedArray = [];
            let pre_processed_array = [];
            if(flat_array.length == 0){
                this.show = true;
                return [];
            }
            pre_processed_array = _.uniqBy(flat_array, 'id');
            pre_processed_array = pre_processed_array.map((item) => {
                let new_item = item;
                // give element the required fields
                new_item["parent"] = new_item.parent_id; //used for webix related stuff
                new_item["value"] = new_item.name; //used for webix related stuff
                new_item['data'] = []; // array of children for the node
                map[item.id] = { ...new_item, };
                return new_item;
            });
            pre_processed_array.forEach((item) => {
                if (item.parent_id !== null) {
                    if (map[item.parent_id]) {
                        map[item.parent_id].data.push(map[item.id]);
                    }
                    else {
                        nestedArray.push(map[item.id]);
                    }
                }
                else {
                    nestedArray.push(map[item.id]);
                }
            });
            this.show = true;
            return nestedArray;
    },
    processTree(data_array){
        console.log(data_array);
        var data = _.cloneDeep(data_array);
        console.log('CHECK ME DATA', data)
        data.forEach((element) => {
                  // give element the required fields
                  element["parent"] = element.$parent; //used for webix related stuff
                  element["value"] = element.name; //used for webix related stuff
                  element["data"] = []; // array of children for the node
                  element['people'] = this.levelEmployeesData

                  if (element['mainID']) {
                    element['mainID'] = null;
                  }

                  if (element.id == this.current_level_id) {
                    element['mainID'] = this.current_level_id;
                  }

                //   if (element.name == this.payload.objective_plan_name) {
                //     element['selected'] = true
                //   } else {
                //     element['selected'] = false
                //   }
              });
              data.forEach((ele) => {   
                  let parent_id = ele.parent_id;
                  console.log(parent_id);
                  if (parent_id == 0) {
                      //* Parent item -- do nothing
                  }
                  else {
                      //* If element is a child element, push to children array.
                      data.forEach((d) => {
                        // console.log(d.parent_id)
                          if (d.id === parent_id) {
                              let childArray = d.data;
                              if (!childArray) {
                                  childArray = [];
                              }
                              childArray.push(ele);
                              d.children = childArray;
                          }
                      });
                  }
              });
        this.linkable_targets = data;
    },
    reloadGoalObjectveData(){
            return new Promise( (resolve, reject) => {
                let params = {
                    level_id: this.current_level_id,
                    milestone_data: 1,
                }
                this.loadPerformanceScreenData({
                    type: 'objective',
                    params: params,
                }).then(()=>{
                    resolve();
                    this.is_loading = false
                })
                .catch(()=>{
                    reject();
                })
            })
    },
    selectedItemEvent() {
        //this.selectedTargetItemEvent(item)
    },
    // selectedTargetItemEvent(item){
    //     let targetLevelId = null;
    //     let targetId = null;
    //     //let value = []
    //     if(this.combined_targets_array.length > 0) {
    //         this.combined_targets_array.forEach( target =>{
    //             if(target.name == item) {
    //                 targetLevelId = target.level_id
    //                 targetId = target.id
    //                 this.targetName = target.name
    //             }
    //         });
    //     } else {
    //         this.searchItems.forEach( target =>{
    //             if(target.name == item) {
    //                 targetLevelId = target.level_id
    //                 targetId = target.id
    //                 this.targetName = target.name
    //             }
    //         });
    //     }
    //     if(targetId == null && item != 'No Target Selected'){
            
    //         let mainTarget = null
    //         this.searchItems.forEach((target) => { 
    //             console.log(target.name);
    //             console.log(item)
    //             if(target.name == item) {
    //                 console.log(target)
    //                 mainTarget = target
    //             }
                    
    //         })
    //         this.loadPerformanceTargets(mainTarget.level_id).then(()=>{
    //             this.buildTree();
    //             this.populateTargetList()
    //             this.show_add_target = false;
    //             let matches = [];
    //             let targetLevelId = mainTarget.level_id;
    //             let targetId = mainTarget.id;
    //             this.targetName = mainTarget.name
    //             matches.push(`${targetLevelId}|target-${targetId}`);
    //             if(matches.length > 0){
    //                 let tree = window.webix.$$('levelsTargetsTree');
    //                     matches.forEach( match => {
    //                         if(tree.exists(match)) {
    //                             let promises = [ this.loadDataFromParents(matches) ];
    //                             Promise.all(promises)
    //                             .then(()=>{
    //                                 setTimeout(() => {
    //                                     this.openTreeNode(matches, true);
    //                                     this.resetTypeahead();
    //                                 }, 1500) 
                                    
    //                             })
    //                         }
    //                     })
                    
    //             }
    //             this.selectedTargetId = `${targetLevelId}|target-${targetId}`
    //         })
    //     } else {
    //         this.is_loading = true
    //         let matches = [];
    //         matches.push(`${targetLevelId}|target-${targetId}`);
    //         if(matches.length > 0){
    //             let tree = window.webix.$$('levelsTargetsTree');
    //                 matches.forEach( match => {
    //                     if(tree.exists(match)) { 
    //                         let promises = [ this.loadDataFromParents(matches) ];
    //                             Promise.all(promises)
    //                             .then(()=>{
                                    
    //                                 setTimeout(() => {
    //                                     this.openTreeNode(matches, true);
    //                                     this.resetTypeahead();
    //                                 }, 1500) 
                                    
    //                             })                   
    //                         // this.openTreeNode(matches, false);
    //                         // this.resetTypeahead();
    //                     }
    //                 }) 
    //         }
    //         this.selectedTargetId = `${targetLevelId}|target-${targetId}`     
    //     }
    // },
},
beforeDestroy() {
    this.modal_payload = {}
},
mounted(){
    this.selectedId = this.payload.level_id
    this.selectedName = this.payload.level_name
    this.selectedType = 'level'
    this.populateList();
    this.setTreeData();
    // this.loadPerformanceTargets(this.selected_level.id).then(()=>{
    //     this.reloadGoalObjectveData()
    // })
    //this.initModal();
    //this.populateList();
    //this.getObjectiveName();
},
}
</script>
<style>
#kanbanModal___BV_modal_header_ {
    background-color: #EBEDF0;
}
#input-group-1__BV_label_ {
    font-size: 11px;
}
#input-group-2__BV_label_ {
    font-size: 11px;
}
#input-group-3__BV_label_ {
    font-size: 11px;
}
#input-group-4__BV_label_ {
    font-size: 11px;
}
#input-group-5__BV_label_ {
    font-size: 11px;
}

.selected_style  {
    border: 2px black;
    color: #0275d8;
    font-weight: bold;
}

.levelTree {
    max-height: 300px;
    overflow-x: scroll;
}
</style>