<template>
    <div>
        <div class="d-flex px-3 small-text">
            <span>Owner:</span>
            <span class="ms-auto">{{  item.owner_user_name }}</span>
        </div>
        <div class="d-flex px-3 small-text">
            <span>Linked Key Actions:</span>
            <span @click="openKAModal" class="ms-auto"><i class="me-1 fas fa-pen cursor-pointer"></i>({{  item.linked_ka_count }})</span>
        </div>
        <div class="d-flex px-3 small-text">
            <span>Duration:</span>
            <span class="ms-auto">
                <span>{{ getDateFormat.start }}</span>
                <span class="text-grey"> to </span>
                <span>{{ getDateFormat.end }}</span>
            </span>
        </div>
        <linkedKeyActionsModal
            v-if="show_linked_key_actions_modal"
            :payload="key_actions_modal_payload"
            @updated="handleModalClose"
        />
        <addActionPlanModal
            v-if="show_action_plan_modal"
            :payload="action_plans_modal_payload"
            @saved="keyActionAdded"
        />
    </div>
    
</template>

<script>
import { format, parseISO } from 'date-fns';
import linkedKeyActionsModal from '../../misc/linkedKeyActionsModal.vue';
import addActionPlanModal from '../../../../kanban/modals/addActionPlanModal.vue';
import Swal from 'sweetalert2'
import _ from 'lodash'
import {
    activityMethods, activityComputed,
    levelComputed, levelMethods,
    performanceComputed, performanceMethods,
    ganttComputed, ganttMethods, 
} from '@/state/helpers';
export default {
    components: {
        linkedKeyActionsModal,
        addActionPlanModal
    },
    data:() => ({
        show_linked_key_actions_modal: false,
        show_action_plan_modal: false,
        key_actions_modal_payload: null,
        action_plans_modal_payload: null,
    }),
    props:{
        item:{
            required: true,
        },
        current_item_is_selected:{
            default: false,
        }
    },
    computed:{
        ...activityComputed,
        ...levelComputed,
        ...performanceComputed,
        ...ganttComputed,
        getDateFormat(){
            const start = format(parseISO(this.item.start_date), 'dd MMM yyyy');
            const end = format(parseISO(this.item.end_date), 'dd MMM yyyy');
            return {
                start: start,
                end: end,
            }
            // let start = this.getSimpleDate(this.item.start_date, '/');
            // //! do some hack to get the end date.. some items have deadline, some items have end_date
            // let end = this.item.deadline ? this.item.deadline : this.item.end_date
            // end = this.getSimpleDate(end, '/');
            // return `${start} - ${end}`
        },
    },
    methods:{
        ...activityMethods,
        ...levelMethods,
        ...performanceMethods,
        ...ganttMethods,
        handleModalClose() {

        },
        openKAModal() {
            this.$nextTick(()=>{
                if(this.kanban_data.length == 0) {
                    Swal.fire({
                    title: "No Key Actions",
                    text: "Would you like to add Key Actions?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#34c38f",
                    cancelButtonColor: "#f46a6a",
                    confirmButtonText: "Yes",
                }).then((result) => {
                    if (result.value) {
                        this.addActionPlan()
                    }
                })
                } else {
                    this.key_actions_modal_payload = this.item
                    this.show_linked_key_actions_modal = true;
                    this.$nextTick(() => {
                        this.$bvModal.show("linkedKeyActionsModal");
                        this.$root.$once("bv::modal::hidden", (event) => {
                            if (event.type == "hidden" && event.componentId == "linkedKeyActionsModal") {
                                this.show_linked_key_actions_modal = false;
                                this.key_actions_modal_payload = null;
                            }
                        });
                    })
                   
                }
                
            })
        },
        addActionPlan() {         
            this.show_action_plan_modal = true;
            let index = _.findIndex(this.levels, {id: this.selected_level.id});
            let name = ''
            if( index != -1){
                name = this.levels[index].name;
            }
            this.action_plans_modal_payload = { level_id: this.selected_level.id, level_name: name};
            if(this.$hasPerm('action-plans') || this.$hasPerm('action-plans-manager')){
            this.$nextTick(()=>{
                this.$bvModal.show('addActionPlanModal');
                this.$root.$once("bv::modal::hidden", (event) => {
                    if (event.type == "hidden" && event.componentId == "addActionPlanModal") {
                        this.show_action_plan_modal = false;
                        this.action_plans_modal_payload = null;
                    }
                });
            })    
            } else {
                this.$swal.fire({
                    title: "Action Plan permission required to add Key Action",
                    icon: "warning",
                    confirmButtonColor: "#34c38f",
                    confirmButtonText: "Ok",
                }) 
            }
        },
        keyActionAdded() {
            this.refreshKanban(this.refresh_kanban = !this.refresh_kanban).then(() => {
                this.key_actions_modal_payload = this.item
                this.show_linked_key_actions_modal = true;
                this.$nextTick(() => {
                    this.$bvModal.show("linkedKeyActionsModal");
                    this.$root.$once("bv::modal::hidden", (event) => {
                        if (event.type == "hidden" && event.componentId == "linkedKeyActionsModal") {
                            this.show_linked_key_actions_modal = false;
                            this.key_actions_modal_payload = null;
                        }
                    });
                })
            })
        },
    },
    mounted(){},
}
</script>

<style>

</style>